export const environment = {
  name: 'oc2021',
  production: true,
  apiUrl: 'https://api.oneclick.chat/',
  videoUrl: 'https://ocsessions.blob.core.windows.net/videos/',
  whiteLabel: 'herewith',
  hasVideoUploadFeature: true,
  joinNowButton: true,
  homepageUrl: '/home',
  hasLogrocket: false,
  version: '2.50',
  build: '1057',
  frontendEnabled: false,
  customHomepageURL: 'https://www.herewith.com/',
  logoLight: 'https://oneclickres.azureedge.net/logos/herewith.png',
  logoDark: 'https://oneclickres.azureedge.net/logos/herewith_white.png',
  companyName: 'herewith',
  companyLongName: 'herewith',
  feedbackRedirectURL: '/left',
  headerBackgroundColor: '#1d99be',
  cmsURL: 'https://cloud.squidex.io/api/content/oneclick',
  cmsURLAssets: 'https://cloud.squidex.io/api/assets/oneclick',
  firebase: {
    apiKey: 'AIzaSyA0IcSKV3aoA1pG6y26089vWCcC-vdybBg',
    authDomain: 'oneclickchat.firebaseapp.com',
    databaseURL: 'https://oneclickchat.firebaseio.com',
    projectId: 'oneclickchat',
    storageBucket: 'oneclickchat.appspot.com',
    messagingSenderId: '499781223927',
    appId: '1:499781223927:web:0eb2de6e1fab1ae4aadaf2',
    measurementId: 'G-Q8BP2YYEHE'
  },
};
